//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import img from '@/assets/img-error.png'
export default {
  name: "taskDetail",
  data() {
    return {
      img:img,
      taskRecordInfo: {
        taskInfo:{}
      },
      consumerInfo: {},
      inputMap: {},
      askAndAnswers:[]
    };
  },
  created() {
    window.document.title = "任务详情";
    this.getTaskInfo();
    this.taskSee();
  },
  methods: {
    onImgError(e){
      console.log(e);
      e.target.src = this.img
    },
    taskSee() {
      const that = this;
      this.axios
        .get("/worktask/wxcp/task/see", {
          params: {
            id: that.$route.query.id,
          },
        })
        .then((res) => {
          console.log(res, 789);
        });
    },
    getTaskInfo() {
      const that = this;
      this.axios
        .get("/worktask/wxcp/task/getRecord", {
          params: {
            id: that.$route.query.id,
          },
        })
        .then((res) => {
          if (res.data.data) {
            that.consumerInfo = res.data.data.consumerInfo
              ? res.data.data.consumerInfo
              : {};
            that.taskRecordInfo = res.data.data.taskRecordInfo
              ? res.data.data.taskRecordInfo
              : {};
            that.inputMap = res.data.data.inputMap
              ? res.data.data.inputMap
              : {};
              that.askAndAnswers = res.data.data.askAndAnswers ? res.data.data.askAndAnswers : []
          } else {
            that.consumerInfo = {};
            that.taskRecordInfo = {};
          }
        });
    },
  },
};
