import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=2e26857f&scoped=true&"
import script from "./Detail.vue?vue&type=script&lang=js&"
export * from "./Detail.vue?vue&type=script&lang=js&"
import style0 from "./Detail.vue?vue&type=style&index=0&id=2e26857f&scoped=true&lang=css&"
import style1 from "./Detail.vue?vue&type=style&index=1&id=2e26857f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e26857f",
  null
  
)

export default component.exports
import {QTable} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable})
